.fsbg {
  display:Block;
  width:100vw;
  height:100vh;
  object-fit:cover;
  opacity:0.7;
}

.mcaption{
color:red;
top:10%;
left:10%;
bottom:auto;
}


.col-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xl-4 {
padding: 1rem;
background-color: #33b5e5;
border: 2px solid #fff;
color: #fff;
text-align: center;
}
